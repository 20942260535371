import React from 'react'

const YoutubVideo = () => {
  return (
    <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%', height: 0 }}>
    <iframe
      style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}
      src="https://www.youtube.com/embed/TrrIlUKldME?si=PbZKTSd2EY7scbns&autoplay=1"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    ></iframe>
  </div>
  
  )
}

export default YoutubVideo