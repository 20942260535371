import React, { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import SVG from "react-inlinesvg";
import "./chatbot.css";
import { Tooltip } from "antd";
import chatbot from "./assets/chatbot.svg";

const arr = [
  "By investing in Sindh, you will be part of a global hub with strong connections to major markets and a knowledge-based economy that is opening to the world.",
  "Sindh is constantly expanding opportunities for international companies.",
  "Our Contact Number is: +92 21 99218874",
  "How can I help you?",
  `1-	APPLICATION FOR REGISTRATION UNDER PARTNERSHIP REGISTRATION ACT, 1932 \n
  2-	PRESCRIBED APPLICATION ON FORM ‘A’\n, 
  3-	PHOTOCOPIES OF CNIC’S  OF ALL THE PARTNERS & TWO WINTNESSES\n.
  4-	PHOTOCOPIES OF PARTNERSHIP DEED\n 
  5-	PAID CHALLAN\n
  6-	DETAIL MAY BE FILLED AND UPLOADED ON WEBSITE (BUSINESS.SINDH.GOV.PK)\n
  `,
  `AFTER MAKING A LOGIN ID ON (BUSINESS.SINDH.GOV.PK) AND DATA FILLING AND UPLOADING THE REQUIRED DOCUMENT THE CHALLAN WILL GENERATE AND WILL BE PAID IN NATIONAL BANK OF PAKISTAN (NBP).`,
  `AFTER FULFILLING OF ALL REQUIRED DOCUMENTS , THE CERTIFICATE WILL BE ISSUED WITHIN SEVEN(07) DAYS.`,
  `THE CERTIFICATE CAN BE VERIFIED AFTER SCANNING QR CODE ON GIVEN CERTIFICATE AND CAN ALSO BE VERIFIED WITH WRITTEN APPLICATION TO THE REGISTRAR OF FIRMS.`,
  `NO, THERE IS NO RENEWAL OF PARTNERSHIP  FIRM UNDER THE LAW . HENCE QUESTION OF RENEWAL DOES NOT ARISE .`,
  `AFTER THE ISSUANCE OF REGISTRATION CERTIFICATE , THE NTN OF SAME IS GENERATED BY FBR.`,
  "I am sorry, but I could not understand your question.",
];
const arr1 = [
  "Why should I invest in Sindh?",
  "How can I setup a business in Sindh?",
  "How can I contact support?",
  "I have a question",
  "What Are The Requirment For Registration Of Partnership Firm Under Partnership ACT 1932 ? ",
  "How Can We Generate Challan And Where It Can Be ID?  ",
  " How Much Time Is Consumed For Issuing Certificate? ",
  "How To Verify The Registration Certificate?",
  "Is There Any Monthly Or Yearly Renewal Fee Of Registration Certificate?",
  " How a Partnership Firm Gets It NTN?",

  // Add more predefined questions as needed
];

const Chatbot = () => {
  const [showChatbot, setShowChatbot] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [hideInput, setHideInput] = useState(true);

  const [predefinedQuestions, setPredefinedQuestions] = useState([
    "Why should I invest in Sindh?",
    "How can I setup a business in Sindh?",
    "How can I contact support?",
    "I have a question",
    "What Are The Requirment For Registration Of Partnership Firm Under Partnership ACT 1932 ? ",
    "How Can We Generate Challan And Where It Can Be ID?  ",
    " How Much Time Is Consumed For Issuing Certificate? ",
    "How To Verify The Registration Certificate?",
    "Is There Any Monthly Or Yearly Renewal Fee Of Registration Certificate?",
    " How a Partnership Firm Gets It NTN?",

    // Add more predefined questions as needed
  ]);
  const [askingAnythingElse, setAskingAnythingElse] = useState(false);
  const [conversationEnded, setConversationEnded] = useState(false);
  const chatContainerRef = useRef(null);
  const toggleChatbot = () => {
    setShowChatbot((prevState) => !prevState);
  };

  const handleOptionClick = (question) => {
    addMessage(question, true); // Add the user's selected question to the chat
    setHideInput(false);
    // Remove the selected question from the predefinedQuestions list
    window.scrollTo(-0, -0);
    setPredefinedQuestions((prevQuestions) =>
      prevQuestions.filter((q) => q !== question)
    );

    // Simulate processing time for the chatbot response
    setTimeout(() => {
      ;
      let response;
      for (let a = 0; a < arr1.length; a++) {
        if (arr1[a] == question) {
          response = arr[a];
          setAskingAnythingElse(true);
          setConversationEnded(false);
          break;
        } else if (arr1[a] == question) {
          response = arr[a];
          setAskingAnythingElse(true);
          setConversationEnded(false);
          break;
        } else if (
          question.toLowerCase() == "hi" ||
          question.toLowerCase() == "hello" ||
          question.toLowerCase() == "hey"
        ) {
          response = "Hello there, Welcome to (CLICK). How can I help you?";
          setAskingAnythingElse(false);
          setConversationEnded(true);
          break;
        } else if (
          question.toLowerCase() == "aoa" ||
          question.toLowerCase() == "assalam o alaikum"
        ) {
          response = "walaikum Assalam";
          setAskingAnythingElse(false);
          setConversationEnded(true);
        } else if (
          question.toLowerCase() == "how are you" ||
          question.toLowerCase() == "kese ho"
        ) {
          response =
            "I'm just a computer program, so I don't have feelings, but I'm here to assist you or you can call directly to +92 21 99218874! How can I help you today?";
        } else if (question == "I have a question") {
          response = arr[3];
          setAskingAnythingElse(false);
          setConversationEnded(true);
          break;
        } else if (arr1[a].toLowerCase().includes(question.toLowerCase())) {
          response = arr[a];
          setAskingAnythingElse(true);
          setConversationEnded(false);
          break;
        } else {
          response = "I am sorry, but I could not understand your question.";
          setAskingAnythingElse(true);
          setConversationEnded(false);
          setHideInput(true);
        }
      }
      addMessage(response);
    }, 2000); // Delay of 1 second for the chatbot response (adjust as needed)
  };
  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };
  function handleKeyDown(event) {
    ;
    console.log(event);
    if (event.key === "Enter") {
      if (userInput !== "") {
        handleOptionClick(userInput);
        setUserInput("");
      }
      console.log("Enter key pressed!");
      // Add your desired functionality here
    }
  }
  const [initialResponsesShown, setInitialResponsesShown] = useState(false);
  const initialResponsesAddedRef = useRef(false);

  useEffect(() => {
    // Add the initial predefined responses only once
    if (!initialResponsesAddedRef.current) {
      addMessage("Hello, I'm here to help you find your way"); // Predefined response: Hello, I'm here to help you find your way
      addMessage("How can I assist you today?"); // Predefined response: How to Chat with Chatbot

      initialResponsesAddedRef.current = true;
    }

    // Scroll to the bottom of the chat container whenever messages change
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, []);
  const handleSendButton = () => {
    if (userInput.trim() !== "") {
      addMessage(userInput, true); // Add the user's message to the chat
      setUserInput(""); // Clear the input box after sending the message
    } else {
      // If the user sends an empty message, show the predefined responses
      setAskingAnythingElse(false);
      setPredefinedQuestions([
        "Why should I invest in Sindh?",
        "How can I setup a business in Sindh?",
        "How can I contact support?",
        // Add more predefined questions as needed
      ]);
    }
  };

  const handleYesButtonClick = () => {
    setAskingAnythingElse(false);
    // Show the predefined questions again
    setPredefinedQuestions([
      "Why should I invest in Sindh?",
      "How can I setup a business in Sindh?",
      "How can I contact support?",
      "I have a question",
      "What Are The Requirment For Registration Of Partnership Firm Under Partnership ACT 1932 ? ",
      "How Can We Generate Challan And Where It Can Be ID?  ",
      " How Much Time Is Consumed For Issuing Certificate? ",
      "How To Verify The Registration Certificate?",
      "Is There Any Monthly Or Yearly Renewal Fee Of Registration Certificate?",
      " How a Partnership Firm Gets It NTN?",

      // Add more predefined questions as needed
    ]);
    setHideInput(true);
  };

  const handleNoButtonClick = () => {
    setAskingAnythingElse(false);
    setConversationEnded(true);
    addMessage("Thank you for contacting CLICK."); // Add the final message to the chat
  };

  const addMessage = (content, isUser = false) => {
    const newMessage = {
      content,
      isUser,
      botName: "CLICK Bot",
      timestamp: new Date().toLocaleString(), // Add the timestamp property to the message
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };
  useEffect(() => {
    // Scroll to the bottom of the chat container whenever messages change
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);
  const [arrow, setArrow] = useState("Show");

  const mergedArrow = useMemo(() => {
    if (arrow === "HideInput") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);

  return (
    <div>
      {showChatbot && (
        <div className="chatbot-container">
          <div className="chatbot-header">
            <h2 className="chatbotHeaderText">
              CLICK VirtualAssistant assistant
            </h2>
            <p style={{ textAlign: "left", marginBottom: "11px" }}>
              Ask me anything
            </p>
          </div>
          <div className="predefined-questions">
            <div className="chat-messages" ref={chatContainerRef}>
              {messages.map((message, index) => (
                <div
                  key={index}
                  className={message.isUser ? "user-message" : "bot-message"}
                >
                  {!message.isUser && (
                    <div className="bot-response">
                      <span className="bot-name">{message.botName}</span>
                      <span className="timestamp">{message.timestamp}</span>
                      {message.content}
                    </div>
                  )}
                  {message.isUser && message.content}
                </div>
              ))}
            </div>
            {askingAnythingElse && !conversationEnded && (
              <div className="d-flex justify-content-center w-100">
                <div className="any-thing-else">
                  <p>Anything else?</p>
                  <div className="d-flex">
                    <button onClick={handleYesButtonClick}>Yes</button>
                    <button onClick={handleNoButtonClick}>No</button>
                  </div>
                </div>
              </div>
            )}
            {!askingAnythingElse && !conversationEnded && (
              <>
                {predefinedQuestions.map((question) => (
                  <button
                    className="chatbot_selected_question"
                    key={question}
                    onClick={() => handleOptionClick(question)}
                  >
                    {question}
                  </button>
                ))}
              </>
            )}
          </div>
          {!hideInput && (
            <div className="chat-input">
              <input
                type="text"
                placeholder="Type your message..."
                value={userInput}
                onKeyDown={handleKeyDown}
                onChange={handleUserInput}
              />
              <button
                className="send-button"
                onClick={(event) => {
                  ;
                  if (userInput !== "") {
                    handleOptionClick(userInput);
                    setUserInput("");
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faPaperPlane}
                  style={{ height: "1.3em" }}
                />
              </button>
            </div>
          )}
          <div className="close-icon" onClick={toggleChatbot}>
            <span>&times;</span>
          </div>
        </div>
      )}
      <Tooltip
        overlayInnerStyle={{ borderRadius: "0px" }}
        color={"linear-gradient(180deg, #720d1d 0%, #1f0308 100%"}
        placement="left"
        title={"I'm Here to assist you"}
        arrow={mergedArrow}
      >
        <div className="chatbot-toggle-button" onClick={toggleChatbot}>
          <SVG
            src={chatbot}
            className="heart"
            height={55}
            width={55}
            style={{
              position: "absolute",
            }}
            fill={"#0f5789"}
            title="React"
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default Chatbot;
