export const translations = {
  'hero-heading': {
    en: 'Discover the Riches of Sindh\nInvest in a Thriving Future',
    ur: 'سندھ کے دولت مند ہنر افزائی کریں۔ ایک خوشحال مستقبل میں سرمایہ کاری کریں۔',
    sd: 'سنڌ جي ڀرتي آهيو\nڀرتي ۾ سرمايو ڪريو',
  },
  'subHeroHeading': {
    en: 'Explore the Province of Sindh and Discover its many assets and Potential for Growth and Investment',
    ur: 'سندھ کے صوبے کا کھیتریں اور اس کی بے شمار خوبصورتیوں کو دریافت کریں اور ترقی اور سرمایہ کاری کے لئے اس کے ممکنات کا پتہ لگائیں۔',
    sd: 'سنڌ جي صوبائي ڪھيتريءَ ڏسو ۽ اهو جي بہت سان ھُڪرپئِوءَ ۽ مواد قوت پڇائيو آهي۔',
  },
  'investButton': {
    en: 'Regulatory Directory',
    ur: ' ریگولرٹی ڈائرکٹری',
    sd: ' ريگولرٽي ڊائريٽري',
  },
  'calculatorButton': {
    en: 'Regulatory Cost Calculator',
    ur: 'ریگولیٹری لاگت کی کیلکولیٹر',
    sd: 'رائيٽورائي ڪارن ڪيلڪوليٽر',
  },
  'FAQ': {
    en: 'FAQ',
    ur: 'عمومی سوالات',
    sd: ''
  },
  'Login': {
    en: 'Login',
    ur: 'لاگ ان',
    sd: 'لاگ ان',
  },
  'Feedback': {
    en: 'Feedback',
    ur: 'تاثرات ',
    sd: 'رائے ',
  },
  'Interactiveregulatory': {
    en: ' Regulatory Directory',
    ur: ' ریگولرٹی ڈائرکٹری',
    sd: ' ریگولرٽي ڊائريڪٽري',
  },
  'home': {
    en: 'Home',
    ur: 'گھر',
    sd: 'گھر',
  },
  'sindhATglance': {
    en: 'Sindh at a Glance',
    ur: 'سندھ کا مختصر جائزہ',
    sd: 'سنڌ جي خلاصو ۾',
  },
  'InvestNow': {
    en: 'Invest Now',
    ur: 'ابھی سرمایہ کاری کریں',
    sd: 'هلن سپرو جون لاگڻو',
  },
  'Manufacturing': {
    en: 'Manufacturing',
    ur: 'تعمیراتی ',
    sd: 'ٽکاري ',
  },
  'Health': {
    en: 'Health',
    ur: 'صحت',
    sd: 'صحت',
  },
  'InformationTechnology': {
    en: 'Information Technology',
    ur: 'معلوماتی تکنالوجی',
    sd: 'انفارميشن ٿيڪنالاجي',
  },
  'Energy': {
    en: 'Energy',
    ur: 'توانائی',
    sd: 'انرجي',
  },
  'Education': {
    en: 'Education',
    ur: 'تعلیم',
    sd: 'تعلیم',
  },
  'Agriculture': {
    en: 'Agriculture',
    ur: 'زراعت',
    sd: 'زرعت',
  },
  'Textile': {
    en: 'Textile',
    ur: 'کپڑا',
    sd: 'ٽيڪسٽائل',
  },
  'Tourism': {
    en: 'Tourism',
    ur: 'سياحت',
    sd: 'سياحت',
  },
  'Talktoexpert': {
    en: 'Talk to expert',
    ur: 'ماہر سے بات کریں',
    sd: 'ماھر سان ٽوڪ',
  },
  'Opportunities': {
    en: 'Sector & Opportunities',
    ur: ' شعبہ اور مواقع',
    sd: 'سيڪٽرز ۽ موقعون',
  },
  'NewsInformation': {
    en: 'News & Information',
    ur: 'خبروں اور معلومات',
    sd: 'خبريون ۽ انفارميشن ',
  },
  'BusinessFacilitation': {
    en: 'Business Facilitation',
    ur: 'کاروبار کی سہولت',
    sd: 'ڪاروباري سهولت',
  },
  'Guids': {
    en: 'GUIDES',
    ur: 'گائیڈز',
    sd: 'رهنما',
  },
  'PreFeasibilityStudies': {
    en: 'Pre-Feasibility Studies',
    ur: 'پری فزیبلٹی اسٹڈیز',
    sd: 'پري فزيبلٽي اڀياس',
  },
  'Studies': {
    en: 'Studies',
    ur: 'مطالعے',
    sd: 'مطالعو'
  },
  'BusinessGuides': {
    en: 'Business Guides',
    ur: 'بزنس گائیڈز',
    sd: 'ڪاروباري ھدايتون',
  },

  'CommercialContracts(English)': {
    en: 'Commercial Contracts (English)',
    ur: 'تجارتی معاہدے (انگریزی)',
    sd: 'تجارتي معاهدو (انگريزي)',
  },
  'CommercialContracts(Urdu)': {
    en: 'Commercial Contracts (Urdu)',
    ur: 'تجارتی معاہدے (اردو)',
    sd: 'تجارتي معاهدو (اردو)',
  },
  'RegulatoryProcedures': {
    en: 'Regulatory Procedures',
    ur: 'ریگولیٹری طریقہ کار',
    sd: 'ريگيوليٽري طريقا',
  },
  'TradeInformationPortal': {
    en: 'Trade Information Portal',
    ur: 'تجارتی معلومات کا پورٹل',
    sd: 'واپاري معلومات پورٽل',
  },
  'InvestmentDepartment': {
    en: 'Investment in Sindh',
    ur: 'سندھ میں سرمایہ کاری',
    sd: 'سنڌ ۾ سيڙپڪاري'
  },
  'Reports': {
    en: 'REPORTS',
    ur: 'رپورٹس',
    sd: 'رپورٽون',
  },
  'ClusterProfiles': {
    en: 'Cluster Profiles',
    ur: 'کلسٹر پروفائلز',
    sd: 'ڪلستر پروفائلز',
  },
  'DistrictEconomicProfiles': {
    en: 'District Economic Profiles',
    ur: 'ڈسٹرکٹ اکنامک پروفائلز',
    sd: 'ضلعي اقتصادي پروفائل',
  },
  'SectorBriefs': {
    en: 'Sector Briefs',
    ur: 'سيڪٽر بريف',
    sd: 'سيڪٽر بريف',
  },
  'StudyReports': {
    en: 'Study Reports',
    ur: 'مطالعاتی رپورٹس',
    sd: 'مطالعي جون رپورٽون',
  },
  'TradeAnalysisSeries': {
    en: 'Trade Analysis Series',
    ur: 'تجارتی تجزیہ سیریز',
    sd: 'واپاري تجزيو سيريز'
  },
  'TradeStatistics': {
    en: 'Trade Statistics',
    ur: 'تجارتی اعدادوشمار',
    sd: 'واپاري شماريات'
  },

  'Resources': {
    en: 'Resources',
    ur: 'وسائل',
    sd: 'وسائل',
  },
  'Download': {
    en: 'Download',
    ur: 'ڈاؤن لوڈ',
    sd: 'ڊائون لوڊ ڪريو ',
  },
  'RegulatoryCatalog': {
    en: 'Regulatory Catalog',
    ur: ' رولز اور قانون کا تلاشی ریکارڈ',
    sd: 'ريگوليٽري ڪيٽيلاگ',
  },
  'statistics': {
    en: 'statistics',
    ur: 'اعداد و شمار',
    sd: 'شماريات',
  },
  'Aboutus': {
    en: 'About us',
    ur: 'ہمارے بارے میں',
    sd: 'اسان جي باريءَ',
  },

  'Introduction': {
    en: 'Introduction',
    ur: 'تعارف',
    sd: 'تعارف',
  },
  'projectobjectives': {
    en: 'Project Objectives',
    ur: "منصوبے کے مقاصد",
    sd: 'پروجيڪٽ جا مقصد'
  },
  'Achievements': {
    en: "Achievements",
    ur: "کامیابیاں",
    sd: "حاصلات"
  },
  'Team': {
    en: "Team",
    ur: "ٹیم",
    sd: "ٽيم"
  },
  'Contactus': {
    en: "Contact us",
    ur: "ہم سے رابطہ کریں۔",
    sd: "اسان سان رابطو ڪريو"
  },
  'Vision': {
    en: 'Vision',
    ur: 'وژن',
    sd: 'ويژن',
  },
  'Components': {
    en: 'Components',
    ur: 'اجزاء',
    sd: 'اجزاء',
  },
  'CLICKSID': {
    en: 'CLICK SID',
    ur: 'کلک کریں سڈ',
    sd: 'ڪلڪ ڪريو سڊ',
  },
  'OurTeam': {
    en: 'Our Team',
    ur: 'ہماری ٹیم',
    sd: 'اسان جي ٽيم',
  },
  'Maps': {
    en: 'Maps',
    ur: 'نقشے ',
    sd: 'نقشون ',
  },
  'Tutorial': {
    en: 'Tutorials',
    ur: 'سبق',
    sd: 'سبق'
  },
  'Updates': {
    en: 'Updates',
    ur: 'اپ ڈیٹس',
    sd: 'اپ ڊيٽس',
  },
  'Headline': {
    en: 'PIU-CLICK LGD is soliciting applications for Procurement & Contracts Management Specialist & Manager Finance. Interested applicants should carefully read the vacancy announcement on our social media pages.',
    ur: "پروجیکٹ امپلیمنٹیشن یونٹ (PIU) لوکل گورنمنٹ ڈپارٹمنٹ (LGD) درخواستوں کی درخواست درخواست اختصاصیت کے لئے طلب کر رہا ہے جیکہ تدارکی اور معاہدات کا انتظام کرنے والے ماہرین اور منیجر مالیت۔ دلچسپ امیدواران کو ہمارے سماجی میڈیا صفحات پر خالی درج کردار کی اعلان کو دھیان سے پڑھنا چاہئے۔",
    sd: "پروجيڪٽ امپليمينٽيشن يونٽ (PIU) لوڪل گورنمنٽ ڊپارٽمينٽ (LGD) ڪنٽريکٽ اينڊ ڪنٽريکٽ اينڊ مينيجر فنانس لاءِ درخواستن تلب ڪرڻ جو ڇا اختصاصيت ٿيندي ماهرين ۽ مينيجر ماليت۔ دلچسپ اميدوارن کي ۾ جو اخبار ساماجي ميڊيا صفحن ۾ روزانو آڻي کي درج ته راهيو ٿا چاهيو.",
  },
  'statistics': {
    en: 'Sindh Statistics',
    ur: "سندھ کے اعدادوشمار",
    sd: " سنڌ جي شماريات",
  },
  'sindhpeople': {
    en: 'People in Sindh',
    ur: "سندھ کے لوگ",
    sd: "سنڌ جا ماڻهو",
  },
  'GDP': {
    en: 'GDP of Sindh',
    ur: "سندھ کی جی ڈی پی",
    sd: "سنڌ جي جي ڊي پي",
  },
  'Services': {
    en: 'People in Services Sector',
    ur: "خدمات کے شعبے میں لوگ",
    sd: "خدمتن جي شعبي ۾ ماڻهو",
  },
  'Awaiting': {
    en: 'Awaiting Opportunities',
    ur: "مواقع کا انتظار",
    sd: "موقعن جي انتظار ۾",
  },
  'Attractives': {
    en: 'Sizeable and Attractive Opportunities available across all Sectors',
    ur: "تمام شعبوں میں قابل قدر اور پرکشش مواقع دستیاب ہیں۔",
    sd: "قابل قدر ۽ پرڪشش موقعا موجود آهن سڀني شعبن ۾",
  },
  'SectorStatistics': {
    en: 'Sector Statistics',
    ur: "سیکٹر کے شماریات",
    sd: "شعبي جي شماريات",
  },
  'Crops': {
    en: 'Value of Major Crops:',
    ur: ":اہم فصلوں کی قیمت",
    sd: ":مکيه فصلن جو قدر",
  },
  'Wheat': {
    en: 'Tons of Wheat',
    ur: "ٹن گندم",
    sd: "ٽين ڪڻڪ",
  },
  'Rice': {
    en: 'Tons of Rice',
    ur: "ٹن چاول",
    sd: "ين چانور",
  },
  'Sugarcane': {
    en: 'Tons of Sugarcane',
    ur: "ٹن گنے",
    sd: "ڪمند جا ٽون",
  },
  'Cotton': {
    en: 'Bales of Cotton',
    ur: "روئی کی گانٹھیں",
    sd: "ڪپهه جا ٿلها",
  },
  'Dollor': {
    en: 'Approx US$1.6 Billion',
    ur: "تقریباً 1.6 بلین امریکی ڈالر",
    sd: "تقريبن US$1.6 بلين",
  },
  'B1': {
    en: 'Learn more about this Sector',
    ur: "اس سیکٹر کے بارے میں مزید جانیں۔",
    sd: "هن شعبي بابت وڌيڪ ڄاڻو",
  },
  'B2': {
    en: 'View all Sectors',
    ur: "تمام شعبوں کو دیکھیں",
    sd: "سڀ شعبا ڏسو",
  },
  'Highlights': {
    en: 'Highlights',
    ur: "جھلکیاں",
    sd: "نمايان",
  },
  'EasYdata': {
    en: 'Ease of doing Business',
    ur: "کاروبار کرنے میں آسانی",
    sd: "ڪاروبار ڪرڻ ۾ آساني",
  },
  'facilitatedTEXT': {
    en: 'The focus of all reforms efforts is to facilitate Government',
    ur: "تمام اصلاحاتی کوششوں کا محور حکومت کو سہولت فراہم کرنا ہے۔",
    sd: "سمورن سڌارن جي ڪوششن جو مرڪز حڪومت کي سهولت ڏيڻ آهي",
  },
  'UpdatedData': {
    en: 'Update',
    ur: "اپ ڈیٹ",
    sd: "تازه ڪاري",
  },
  'Economies': {
    en: 'Economy',
    ur: "معیشت",
    sd: "معيشت",
  },
  'EconTEXT': {
    en: 'We are 24th Largest Economy',
    ur: "ہم 24ویں بڑی معیشت ہیں",
    sd: "اسان 24 هين وڏي معيشت آهيون",
  },
  'iNVEstText': {
    en: 'Investment & Sector Policies',
    ur: "سرمایہ کاری اور شعبے کی پالیسیاں",
    sd: "سيڙپڪاري ۽ شعبي جي پاليسين",
  },
  'LibiNVEstText': {
    en: 'Sindh offers liberal Investment',
    ur: "سندھ لبرل سرمایہ کاری کی پیشکش کرتا ہے",
    sd: "سنڌ لبرل سيڙپڪاري پيش ڪري ٿي",
  },
  'EconomicZonesText': {
    en: 'Special Economic Zone',
    ur: "خصوصی اقتصادی زون",
    sd: "خاص اقتصادي زون",
  },
  'blanketText': {
    en: 'Is a blanket term for various types of Specialized Zones',
    ur: "مختلف قسم کے اسپیشلائزڈ زونز کے لیے ایک کمبل کی اصطلاح ہے",
    sd: "مختلف قسم جي اسپيشلائزڊ زونن لاءِ هڪ خالي اصطلاح آهي",
  },
  'FavourEnvironment': {
    en: 'Favourable Environment, Strategic Location, Growing Economy,Natural Resources, Investment Opportunities, And Supportive Government Policies',
    ur: "سازگار ماحول، اسٹریٹجک مقام، بڑھتی ہوئی معیشت، قدرتی وسائل، سرمایہ کاری کے مواقع، اور معاون حکومتی پالیسیاں",
    sd: "سازگار ماحول، اسٽريٽجڪ مقام، وڌندڙ معيشت، قدرتي وسيلا، سيڙپڪاري جا موقعا، ۽ معاون حڪومتي پاليسيون.",
  },
  'NaturalGas': {
    en: 'Cubic Feet of Natural Gas Production',
    ur: "قدرتی گیس کی پیداوار کے کیوبک فٹ",
    sd: "ڪعبي فوٽ قدرتي گئس جي پيداوار",
  },
  'TonOfCoal': {
    en: 'Tonnes of Coal Reserves',
    ur: "ٹن کوئلے کے ذخائر",
    sd: "ٽنن ڪوئلي جا ذخيرا",
  },
  'ElectricityGeneration': {
    en: 'Of Electricity Generation Capacity',
    ur: "بجلی پیدا کرنے کی صلاحیت کا",
    sd: "بجلي پيدا ڪرڻ جي صلاحيت",
  },
  'TotalFish': {
    en: 'Of Pakistans Total Fish Production',
    ur: "پاکستان کی کل مچھلی کی پیداوار",
    sd: "پاڪستان جي ڪل مڇي جي پيداوار",
  },
  'TonOfCement': {
    en: 'Tonnes of Cement Production',
    ur: "ٹن سیمنٹ کی پیداوار",
    sd: "ٽين سيمينٽ جي پيداوار",
  },
  'TextileMills': {
    en: 'Textile Mills with over 300,000 workers',
    ur: "ٹیکسٹائل ملز جس میں 300,000 سے زیادہ کارکن ہیں",
    sd: "300,000 کان وڌيڪ ڪارڪنن سان ٽيڪسٽائل ملز",
  },
  'Billion': {
    en: 'Billion',
    ur: "ارب",
    sd: "بلين",
  },
  'Million': {
    en: 'Million',
    ur: "ملين",
    sd: "ملين",
  },
  'Trillion': {
    en: 'Trillion',
    ur: "ٹریلین",
    sd: "ٽريلين",
  },
  'Megawatts': {
    en: 'Megawatts',
    ur: "میگاواٹ",
    sd: "ميگا واٽ",
  },
  'Explore': {
    en: 'Explore Opportunities',
    ur: "مواقع دریافت کریں",
    sd: "ڳولا جا موقعا",
  },
  'Messages': {
    en: 'Messages',
    ur: "پیغامات",
    sd: "نياپا",
  },
  'Testimonials': {
    en: 'Testimonials',
    ur: "تعریفیں",
    sd: "شاھدي",
  },
  'Help': {
    en: 'How we Help',
    ur: "ہم کس طرح مدد کرتے ہیں۔",
    sd: "اسان ڪيئن مدد ڪندا آهيون",
  },
  'Testimonials': {
    en: 'Testimonials',
    ur: "تعریفیں",
    sd: "شاھدي",
  },
  'BREADY': {
    en: 'B-READY',
    ur: "بی تیار",
    sd: "بي تيار",
  },
  'RegulatoryCost': {
    en: 'Regulatory Cost',
    ur: "ریگولیٹری لاگت",
    sd: "ريگيوليٽري لاڳت",
  },
  'Calculator': {
    en: 'Calculator',
    ur: "کیلکولیٹر",
    sd: "حساب ڪندڙ",
  },
  'KarachiFund': {
    en: 'Karachi Fund',
    ur: "کراچی فنڈ",
    sd: "ڪراچي فنڊ",
  },
  'RequestInformation': {
    en: 'Request Information',
    ur: "معلومات کی درخواست کریں",
    sd: "معلومات جي درخواست ڪريو",
  },
  'FooterText': {
    en: 'Your one-stop source for investment opportunities in Sindh, Pakistan. Our platform connects you with the latest information on the business environment, policies, and incentives available in the region.',
    ur: "سندھ، پاکستان میں سرمایہ کاری کے مواقع کے لیے آپ کا ون اسٹاپ ذریعہ۔ ہمارا پلیٹ فارم آپ کو خطے میں دستیاب کاروباری ماحول، پالیسیوں اور مراعات کے بارے میں تازہ ترین معلومات سے جوڑتا ہے",
    sd: "سنڌ، پاڪستان ۾ سيڙپڪاري جا موقعا حاصل ڪرڻ لاءِ توهان جو ون اسٽاپ ذريعو. اسان جو پليٽ فارم توهان کي علائقي ۾ موجود ڪاروباري ماحول، پاليسين، ۽ ترغيبات تي جديد معلومات سان ڳنڍي ٿو.",
  },
  'LocalDeparment': {
    en: 'Local Government Deparment',
    ur: "محکمہ لوکل گورنمنٹ",
    sd: "لوڪل گورنمينٽ ڊپارٽمينٽ",
  },
  'SRB': {
    en: 'Sindh Revenue Board',
    ur: "سندھ ریونیو بورڈ",
    sd: "سنڌ روينيو بورڊ",
  },
  'BoardOfRevenue': {
    en: 'Board of Revenue Sindh',
    ur: 'بورڈ آف ریونیو سندھ',
    sd: 'بورڊ آف روينيو سنڌ'
  },
  'SBCA': {
    en: 'Sindh Building Control Authority',
    ur: "سندھ بلڈنگ کنٹرول اتھارٹی",
    sd: "سنڌ بلڊنگ ڪنٽرول اٿارٽي",
  },

  'SHC': {
    en: 'Sindh Healthcare Commission',
    ur: "سندھ ہیلتھ کیئر کمیشن",
    sd: "سنڌ هيلٿ ڪيئر ڪميشن",
  },
  'SEPA': {
    en: 'Sindh Environment Protection Agency',
    ur: "سندھ انوائرمنٹ پروٹیکشن ایجنسی",
    sd: "سنڌ انوائرمينٽ پروٽيڪشن ايجنسي",
  },

  'SFA': {
    en: 'Sindh Food Authority',
    ur: "سندھ فوڈ اتھارٹی",
    sd: "سنڌ فوڊ اٿارٽي",
  },
  'CD': {
    en: 'College Department',
    ur: "کالج کا شعبہ",
    sd: "ڪاليج ڊپارٽمينٽ",
  },
  'SID': {
    en: 'Sindh Investment Department',
    ur: "محکمہ سرمایہ کاری سندھ",
    sd: "سنڌ سيڙپڪاري کاتو",
  },
  'ID': {
    en: 'Industrial Department',
    ur: "صنعتی محکمہ",
    sd: "صنعتي کاتو",
  },
  'AD': {
    en: 'Agriculture Department',
    ur: "محکمہ زراعت",
    sd: "حساب ڪندڙ",
  },
  'ED': {
    en: 'Energy Department',
    ur: "محکمہ توانائی",
    sd: "توانائي کاتي",
  },
  'SED': {
    en: 'School Education Department',
    ur: "محکمہ سکول ایجوکیشن",
    sd: "اسڪول ايجوڪيشن ڊپارٽمينٽ",
  },
  'HD': {
    en: 'Health Department',
    ur: "محکمہ صحت",
    sd: "صحت کاتي",
  },
  seravgeBoard: {
    en: "Karachi Water & Sewerage Board",
    ur: "کراچی واٹر اینڈ سیوریج بورڈ",
    sd: "ڪراچي واٽر اينڊ سيوريج بورڊ"
  },
  Kmc: {
    en: "Karachi Metropolitan Corporation (KMC)",
    ur: "کراچی میٹروپولیٹن کارپوریشن",
    sd: "ڪراچي ميٽروپوليٽن ڪارپوريشن"
  },
  'Floor': {
    en: '1st Floor, FTC Building',
    ur: "پہلی منزل، ایف ٹی سی بلڈنگ",
    sd: "پهرين منزل، FTC بلڊنگ",
  },
  'Shahrah': {
    en: '1st Floor, Block-A, FTC Building Shahrah-e-Faisal, Karachi.',
    ur: "پہلی منزل، بلاک اے، ایف ٹی سی بلڈنگ شاہراہ فیصل، کراچی۔",
    sd: "فرسٽ فلور، بلاڪ اي، ايف ٽي سي بلڊنگ شاهراه فيصل، ڪراچي."
  },
  'Karachi': {
    en: 'Karachi, Karachi City, Sindh',
    ur: "کراچی، کراچی شہر، سندھ",
    sd: "ڪراچي، ڪراچي شهر، سنڌ",
  },
  'Notifications': {
    en: 'Notifications',
    ur: "اطلاعات",
    sd: "نوٽيفڪيشن",
  },
  'Presentations': {
    en: 'Presentations',
    ur: "پیشکشیں",
    sd: "پيشيون",
  },
  'Downloads': {
    en: 'Downloads',
    ur: "ڈاؤن لوڈ",
    sd: "ڊائون لوڊ",
  },
  'RelatedLinks': {
    en: 'Related Links',
    ur: "متعلقہ لنکس",
    sd: "لاڳاپيل لنڪ",
  },
  'Contact': {
    en: 'Contact',
    ur: "رابطہ کریں۔",
    sd: "رابطو",
  },
  'Newsletter': {
    en: 'Sign up for Newsletter',
    ur: "نیوز لیٹر کے لیے سائن اپ کریں",
    sd: "نيوز ليٽر لاءِ سائن اپ ڪريو",
  },
  
  'Copyright': {
    en: '©️ 2024 Click. All rights reserved',
    ur: "©️ 2024 کلک کریں۔ جملہ حقوق محفوظ ہیں",
    sd:'©️ 2024 ڪلڪ ڪريو. سڀ حق محفوظ آهن'
  },
  'Powered': {
    en: 'Powered by Infoaccess',
    ur: " یافتہ تقویت ذریعہ کے معلومات تک رسائی",
    sd: "  ڪيل پاران رسائي جي  معلومات",
  },
  'Growth': {
    en: 'Unleash Growth and Opportunity',
    ur: "ترقی اور مواقع کو دور کریں",
    sd: "ترقي ۽ فرصت کي ختم ڪريو",
  },
  'Version': {
    en: 'Version',
    sd: "ورژن",
    ur: "ورژن",
  },
  'Stage': {
    en: 'Stage',
    ur: "اسٹیج",
    sd: "اسٽيج",
  },
  'RegisteryourCOMP': {
    en: 'Register Your Company',
    ur: "اپنی کمپنی کو رجسٹر کریں",
    sd: "پنھنجي ڪمپني کي رجسٽر ڪريو",
  },
  'Register': {
    en: 'Register',
    ur: "رجسٹر کریں",
    sd: "رجسٽر",
  },
  'launching': {
    en: 'The first step in launching your business in Sindh is to register your company.',
    ur: "سندھ میں اپنا کاروبار شروع کرنے کا پہلا قدم اپنی کمپنی کو رجسٹر کرنا ہے",
    sd: "سنڌ ۾ پنهنجو ڪاروبار شروع ڪرڻ جو پهريون قدم توهان جي ڪمپني کي رجسٽر ڪرڻ آهي",
  },
  'BankAccount': {
    en: 'Open a Bank Account',
    ur: "رجسٹر کریں",
    sd: "هڪ بئنڪ اڪائونٽ کوليو",
  },
  'establishing': {
    en: 'The next vital step in establishing your business in Sindh is to open a bank account.',
    ur: "سندھ میں اپنا کاروبار قائم کرنے کا اگلا اہم مرحلہ بینک اکاؤنٹ کھولنا ہے",
    sd: "سنڌ ۾ پنهنجو ڪاروبار قائم ڪرڻ لاءِ ايندڙ اهم قدم هڪ بئنڪ اڪائونٽ کولڻ آهي",
  },
  'STRN': {
    en: 'Apply for a STRN',
    ur: "کے لیے درخواست دیں STRN",
    sd: "لاءِ درخواست ڏيو STRN",
  },
  'SalesTax': {
    en: 'Ensure compliance and broaden your market reach by applying for a Sales Tax Registration Number (SPRL).',
    ur: "سیلز ٹیکس رجسٹریشن نمبر (SPRL) کے لیے درخواست دے کر تعمیل کو یقینی بنائیں اور اپنی مارکیٹ تک رسائی کو وسیع کریں۔",
    sd: "سيلز ٽيڪس رجسٽريشن نمبر (SPRL) لاءِ درخواست ڪندي تعميل کي يقيني بڻايو ۽ پنهنجي مارڪيٽ جي پهچ کي وڌايو.",
  },
  'InvestSindh': {
    en: 'Invest in Sindh',
    ur: "سندھ میں سرمایہ کاری کریں",
    sd: "سنڌ ۾ سيڙپڪاري ڪريو",
  },
  'InvestorGuide': {
    en: 'Investor’s Guide',
    ur: "سرمایہ کار گائیڈ",
    sd: "سيڙپڪار جي رهنمائي",
  },
  'PublicationTitle': {
    en: 'Publication Title',
    ur: "اشاعت کا عنوان",
    sd: "اشاعت جو عنوان",
  },
  'Shortdescription': {
    en: 'Short description...',
    ur: "مختصر کوائف",
    sd: "مختصر وضاحت",
  },
  'ShareYourFeedback': {
    en: 'Share Your Feedback',
    ur: "اپنی رائے شیئر کریں",
    sd: "پنهنجا تاثرات شيئر ڪريو",
  },
  'MorePublications': {
    en: 'View More Publications',
    ur: "مزید اشاعتیں دیکھیں",
    sd: "وڌيڪ اشاعتون ڏسو",
  },

  'WhySindh': {
    en: 'Why Sindh',
    ur: "سندھ کیوں؟",
    sd: "سنڌ ڇو؟",
  },
  'DAOS': {
    en: 'Discover the Advantages of Sindh',
    ur: "سندھ کے فوائد دریافت کریں",
    sd: "سنڌ جا فائدا دريافت ڪريو",
  },

  'WhySind1stpara': {
    en: "Uncover the remarkable advantages that Sindh, Pakistan's vibrant province, holds for investors and businesses alike. Sindh boasts a strategic location, serving as a key trade route between East and West, providing access to a vast consumer market. Its thriving industries, including textiles, manufacturing, agriculture, and services, offer ample investment opportunities. With a skilled workforce, supportive government policies, and a growing infrastructure, Sindh provides a conducive business environment. Explore this section to learn more about the favorable tax incentives, abundant resources, and cultural richness that make Sindh an enticing destination for those seeking growth, profitability, and a strong competitive edge.",
    ur: "ن قابل ذکر فوائد سے پردہ اٹھائیں جو سندھ، پاکستان کا متحرک صوبہ ہے، جو سرمایہ کاروں اور کاروباروں کے لیے یکساں ہے۔ سندھ ایک اسٹریٹجک محل وقوع پر فخر کرتا ہے، جو مشرق اور مغرب کے درمیان ایک اہم تجارتی راستے کے طور پر کام کرتا ہے، جس سے صارفین کی وسیع مارکیٹ تک رسائی حاصل ہوتی ہے۔ اس کی ترقی پذیر صنعتیں، بشمول ٹیکسٹائل، مینوفیکچرنگ، زراعت، اور خدمات، سرمایہ کاری کے کافی مواقع فراہم کرتی ہیں۔ ہنرمند افرادی قوت، معاون حکومتی پالیسیوں اور بڑھتے ہوئے انفراسٹرکچر کے ساتھ، سندھ کاروبار کے لیے سازگار ماحول فراہم کرتا ہے۔ سازگار ٹیکس مراعات، وافر وسائل، اور ثقافتی فراوانی کے بارے میں مزید جاننے کے لیے اس سیکشن کو دیکھیں جو کہ ترقی، منافع اور مضبوط مسابقتی برتری کے خواہاں افراد کے لیے سندھ کو ایک پرکشش مقام بناتی ہے۔",
    sd: " صوبو، سيڙپڪارن ۽ ڪاروبارن لاءِ هڪجهڙا فائدا حاصل ڪريو. سنڌ هڪ اسٽريٽجڪ مقام تي فخر ڪري ٿي، اوڀر ۽ اولهه جي وچ ۾ هڪ اهم واپاري رستي جي حيثيت سان خدمت ڪندي، هڪ وسيع صارف مارڪيٽ تائين رسائي فراهم ڪري ٿي. ان جي ترقي پذير صنعتون، بشمول ٽيڪسٽائل، پيداوار، زراعت، ۽ خدمتون، ڪافي سيڙپڪاري جا موقعا پيش ڪن ٿا. هڪ ماهر افرادي قوت، معاون حڪومتي پاليسين، ۽ وڌندڙ انفراسٽرڪچر سان، سنڌ هڪ سازگار ڪاروباري ماحول فراهم ڪري ٿي. هن سيڪشن کي ڳوليو وڌيڪ سکڻ لاءِ سازگار ٽيڪس مراعات، وسيع وسيلا، ۽ ثقافتي شاهوڪار جيڪي سنڌ کي ترقي، منافعي، ۽ مضبوط مقابلي واري برتري حاصل ڪرڻ وارن لاءِ هڪ پرجوش منزل بڻائين ٿا.",
  },

  'GatewayToGrowth': {
    en: 'Gateway to Growth',
    ur: " گیٹ وے ٹو گروتھ",
    sd: "واڌ ويجهه جو دروازو"
  },

  'WhySindh2ndpara': {
    en: " Unlocking Opportunities through Sindh's Strategic Location, Sindh has a strategic geographical location, serving as a major gateway between Central Asia, the Middle East, and South Asia. It provides access to important trade routes, including the Arabian Sea, making it an ideal location for businesses looking to establish connections and expand their market reach.",
    ur: " سندھ کے اسٹریٹجک مقام، سندھ کے ذریعے مواقع کو کھولنا ایک اسٹریٹجک جغرافیائی محل وقوع ہے، جو ایک بڑے گیٹ وے کے طور پر کام کرتا ہے۔وسطی ایشیا، مشرق وسطیٰ اور جنوبی ایشیا کے درمیان۔ یہ دیتا یے بحیرہ عرب سمیت اہم تجارتی راستوں تک رسائی  یہ کنکشن قائم کرنے کے خواہاں کاروباروں کے لیے ایک مثالی مقام ہے۔اور ان کی مارکیٹ تک رسائی کو وسعت دیں۔",
    sd: "سنڌ جي اسٽريٽجڪ مقام ذريعي موقعن کي کولڻ، سنڌ هڪ اسٽريٽجڪ جاگرافيائي مقام آهي، هڪ اهم گيٽ وي جي طور تي خدمت ڪري رهيو آهي وچ ايشيا، وچ اوڀر ۽ ڏکڻ ايشيا جي وچ ۾. اهو مهيا ڪري ٿو عرب سمنڊ سميت اهم واپاري رستن تائين پهچ اهو ڪاروبار لاءِ هڪ مثالي مقام آهي جيڪو ڪنيڪشن قائم ڪرڻ چاهي ٿو ۽ انهن جي مارڪيٽ جي پهچ کي وڌايو.",
  },

  'LearnMore': {
    en: 'Learn More',
    ur: "اورجانیے",
    sd: "وڌيڪ سکو",
  },

  'EconomicPowerhouse': {
    en: 'Economic Powerhouse',
    ur: "اقتصادی پاور ہاؤس",
    sd: "اقتصادي پاور هائوس",
  },

  'WhySindh3rdpara': {
    en: "Sindh's Thriving Sectors Drive Investment Potential, Sindh is one of the most economically significant provinces in Pakistan. It boasts a diverse economy, with thriving sectors such as agriculture, manufacturing, services, and finance. The province's economic growth is driven by its vibrant cities like Karachi, which is the financial and commercial hub of Pakistan.",
    ur: "سندھ کے فروغ پزیر سیکٹرز سرمایہ کاری کی صلاحیت کو بڑھاتے ہیں، سندھ پاکستان کے سب سے زیادہ معاشی لحاظ سے اہم صوبوں میں سے ایک ہے۔ زراعت، مینوفیکچرنگ، خدمات اور مالیات جیسے فروغ پزیر شعبوں کے ساتھ یہ متنوع معیشت کا حامل ہے۔ صوبے کی معاشی نمو کراچی جیسے متحرک شہروں سے چلتی ہے، جو پاکستان کا مالیاتی اور تجارتی مرکز ہے۔",
    sd: "سنڌ جي ترقي يافته شعبن ۾ سيڙپڪاري جي صلاحيت، سنڌ پاڪستان جي اقتصادي لحاظ کان اهم صوبن مان هڪ آهي. اهو هڪ متنوع معيشت تي فخر ڪري ٿو، ترقي پذير شعبن جهڙوڪ زراعت، پيداوار، خدمتون، ۽ فنانس. صوبي جي معاشي ترقي ان جي متحرڪ شهرن جهڙوڪ ڪراچي، جيڪو پاڪستان جو مالي ۽ تجارتي مرڪز آهي، ذريعي هلي ٿو.",
  },

  'BuildingBridgestoS': {
    en: "Building Bridges To Success",
    ur: "کامیابی کے لیے پل بنانا ",
    sd: " ڪاميابي لاء پل تعمير ڪرڻ",
  },

  'WhySindh4thpara': {
    en: " Sindh's Infrastructure Development Attracts Investors, Sindh has been focusing on improving its infrastructure to facilitate business activities and attract investments. There have been significant investments in transportation, including road networks, ports, and airports, which enhance connectivity within the province and with the rest of the country. ",
    ur: "سندھ کا انفراسٹرکچر ڈویلپمنٹ سرمایہ کاروں کو راغب کرتا ہے، سندھ کاروباری سرگرمیوں کو آسان بنانے اور سرمایہ کاری کو راغب کرنے کے لیے اپنے انفراسٹرکچر کو بہتر بنانے پر توجہ دے رہا ہے۔ سڑکوں کے نیٹ ورکس، بندرگاہوں اور ہوائی اڈوں سمیت نقل و حمل میں نمایاں سرمایہ کاری کی گئی ہے، جو صوبے کے اندر اور ملک کے باقی حصوں کے ساتھ رابطے کو بڑھاتے ہیں۔ ",
    sd: " سنڌ جي انفراسٽرڪچر ڊولپمينٽ سيڙپڪارن کي راغب ڪري ٿي، سنڌ ڪاروباري سرگرمين کي آسان بڻائڻ ۽ سيڙپڪاري کي راغب ڪرڻ لاءِ پنهنجي انفراسٽرڪچر کي بهتر بنائڻ تي ڌيان ڏئي رهي آهي. ٽرانسپورٽ جي شعبن ۾ اهم سيڙپڪاري ڪئي وئي آهي، جنهن ۾ روڊ نيٽ ورڪ، بندرگاهن ۽ هوائي اڏا شامل آهن، جيڪي صوبي اندر ۽ ملڪ جي باقي حصن سان رابطا وڌائين ٿا.",
  },

  'DiverseHorizons': {
    en: "Diverse Horizons",
    ur: "متنوع افق",
    sd: " مختلف افقون",
  },

  'WhySindh5thpara': {
    en: "Exploring Lucrative Investment Opportunities in Sindh's Sectors, Sindh offers a range of investment opportunities across various sectors. For instance, the agriculture sector presents opportunities in crop cultivation, livestock farming, and fisheries. The manufacturing sector is expanding, with potential investments in textiles, garments, chemicals, and engineering goods. Additionally, the province provides a favorable environment for investment in sectors such as information technology, renewable energy, and real estate. ",
    ur: " سندھ کے شعبوں میں سرمایہ کاری کے منافع بخش مواقع تلاش کرتے ہوئے، سندھ مختلف شعبوں میں سرمایہ کاری کے وسیع مواقع پیش کرتا ہے۔ مثال کے طور پر، زراعت کا شعبہ فصلوں کی کاشت، مویشیوں کی کاشتکاری اور ماہی گیری کے مواقع فراہم کرتا ہے۔ ٹیکسٹائل، گارمنٹس، کیمیکلز اور انجینئرنگ کے سامان میں ممکنہ سرمایہ کاری کے ساتھ مینوفیکچرنگ کا شعبہ پھیل رہا ہے۔ مزید برآں، صوبہ انفارمیشن ٹیکنالوجی، قابل تجدید توانائی اور رئیل اسٹیٹ جیسے شعبوں میں سرمایہ کاری کے لیے سازگار ماحول فراہم کرتا ہے۔",
    sd: " سنڌ جي شعبن ۾ سيڙپڪاري جا منافعي جا موقعا ڳوليندي، سنڌ مختلف شعبن ۾ سيڙپڪاري جا ڪيترائي موقعا پيش ڪري ٿي. مثال طور، زراعت جو شعبو پيش ڪري ٿو موقعا فصلن جي پوکيءَ، جانورن جي پوک ۽ ماهيگيريءَ ۾. پيداواري شعبو وڌي رهيو آهي، ٽيڪسٽائل، گارمينٽس، ڪيميڪل، ۽ انجنيئرنگ سامان ۾ امڪاني سيڙپڪاري سان. ان کان علاوه، صوبو انفارميشن ٽيڪنالاجي، قابل تجديد توانائي، ۽ ريئل اسٽيٽ جهڙن شعبن ۾ سيڙپڪاري لاءِ سازگار ماحول فراهم ڪري ٿو.",
  },

  'SeizeTheOpportunity': {
    en: "Seize the Opportunity",
    ur: "موقع سے فائدہ اٹھائیے ",
    sd: "   فرصت جو فائدو وٺو  "
  },

  'Opportunities': {

    en: "Opportunities",
    ur: "مواقع",
    sd: "موقعا"
  },

  'opportunityhead1': {
    en: 'Explore Lucrative Opportunities in Sindh',
    ur: "سندھ میں منافع بخش مواقع تلاش کریں",
    sd: "سنڌ ۾ منافعي جا موقعا ڳوليو"

  },

  'opportunitypara1': {
    en: "Explore a myriad of exciting opportunities in Sindh, Pakistan's vibrant province. From thriving industries to emerging sectors, Sindh offers a fertile ground for investment, innovation, and growth, presenting a gateway to unparalleled prospects and a pathway to prosperity.",
    ur: 'پاکستان کے متحرک صوبہ سندھ میں بے شمار دلچسپ مواقع دریافت کریں۔ فروغ پزیر صنعتوں سے لے کر ابھرتے ہوئے شعبوں تک، سندھ سرمایہ کاری، اختراعات اور ترقی کے لیے ایک زرخیز میدان پیش کرتا ہے، جو بے مثال امکانات کا گیٹ وے اور خوشحالی کا راستہ پیش کرتا ہے۔',
    sd: 'سنڌ، پاڪستان جي متحرڪ صوبو ۾ ڪيترن ئي دلچسپ موقعن جي ڳولا ڪريو. ترقي پذير صنعتن کان وٺي اڀرندڙ شعبن تائين، سنڌ سيڙپڪاري، جدت ۽ واڌ ويجهه لاءِ هڪ زرخيز ميدان پيش ڪري ٿي، جيڪا بي مثال امڪانن لاءِ گيٽ وي ۽ خوشحالي جو رستو پيش ڪري ٿي.',

  },

  'AgricultureandFoodP': {
    en: "Agriculture & Food Processing",
    ur: " زراعت اور فوڈ پروسیسنگ",
    sd: "زراعت ۽ کاڌي جي پروسيسنگ",
  },

  'opportunitypara2': {
    en: " Harvest Success in Sindh's Bountiful Fields. Dive into the fertile agricultural landscape of Sindh and explore the vast potential for investment and growth in the agriculture and food processing sectors, where abundant resources, favorable climate, and a rich farming heritage converge to create a recipe for success.",
    ur: " سندھ کے بے شمار کھیتوں میں کامیابی حاصل کریں۔ سندھ کے زرخیز زرعی منظر نامے میں غوطہ لگائیں اور اس کے لیے وسیع امکانات کو دریافت کریں۔ زراعت اور فوڈ پروسیسنگ میں سرمایہ کاری اور ترقی وہ شعبے، جہاں پرچر وسائل، سازگار آب و ہوا، اور ایک بھرپور کاشتکاری کا ورثہ کامیابی کے لیے ایک نسخہ تیار کرنے کے لیے اکٹھا ہوتا ہے۔",
    sd: " سنڌ جي باوقار ميدانن ۾ ڪاميابي حاصل ڪريو. سنڌ جي زرخيز زرعي منظرنامي تي غور ڪريو ۽ ان لاءِ وسيع امڪانن کي ڳوليوزراعت ۽ فوڊ پروسيسنگ ۾ سيڙپڪاري ۽ ترقي شعبا، جتي گھڻا وسيلا، سازگار آبهوا، ۽ زرخيز زراعت جو ورثو ملن ٿا ته جيئن ڪاميابيءَ جو رستو پيدا ٿئي."


  },

  'Poultry': {
    en: "Poultry",
    ur: " پولٹری",
    sd: " مرغي",

  },

  'opportunitypara3': {
    en: "Flock to Profitable Ventures in Sindh's Poultry Industry. Capitalize on the booming demand for poultry products in Sindh as we offer you a gateway to tap into a thriving industry supported by state-of-the-art facilities, advanced farming techniques, and a strong market presence. Explore the potential for sustainable growth and profitability in the poultry sector and seize the opportunity to feather your business's success.",
    ur: "سندھ کی پولٹری انڈسٹری میں منافع بخش کاروبار کی طرف بڑھیں۔ سندھ میں پولٹری مصنوعات کی بڑھتی ہوئی مانگ سے فائدہ اٹھائیں کیونکہ ہم آپ کو جدید ترین سہولیات، جدید کاشتکاری کی تکنیکوں، اور مضبوط مارکیٹ کی موجودگی سے تعاون یافتہ ایک فروغ پزیر صنعت میں داخل ہونے کا ایک گیٹ وے پیش کرتے ہیں۔ پولٹری کے شعبے میں پائیدار ترقی اور منافع کے امکانات کو دریافت کریں اور اپنے کاروبار کی کامیابی کو پنکھے لگانے کے موقع سے فائدہ اٹھائیں۔",
    sd: " سنڌ جي پولٽري جي صنعت ۾ منافعي بخش ڪاروبار ڏانهن رخ ڪريو. سنڌ ۾ پولٽري جي شين جي وڌندڙ گهرج تي سرمائيداري ڪريو جيئن اسان توهان کي پيش ڪريون ٿا هڪ ترقي پذير صنعت ۾ داخل ٿيڻ لاءِ جيڪو جديد سهولتن، جديد فارمنگ ٽيڪنڪ، ۽ مضبوط مارڪيٽ جي موجودگي جي مدد سان. پولٽري شعبي ۾ پائيدار ترقي ۽ نفعي جي امڪانن جي ڳولا ڪريو ۽ پنهنجي ڪاروبار جي ڪاميابيءَ کي پرکڻ جو موقعو حاصل ڪريو.",
  },

  'frozenfoodandp': {
    en: "Frozen food & Processing",
    ur: " منجمد کھانا اور پروسیسنگ",
    sd: " منجمد کاڌو ۽ پروسيسنگ"

  },

  'opportunitypara4': {
    en: " Unleash the Potential of Sindh's Frozen Food Industry. Discover the lucrative prospects of frozen food and processing in Sindh, where cutting-edge facilities, quality standards, and a growing market demand converge to create a recipe for success. Capitalize on this opportunity to meet the evolving needs of consumers and carve your niche in the thriving frozen food industry.",
    ur: " سندھ کی منجمد فوڈ انڈسٹری کی صلاحیت کو اجاگر کریں۔ سندھ میں فروزن فوڈ اور پروسیسنگ کے منافع بخش امکانات دریافت کریں، جہاں جدید ترین سہولیات، معیار کے معیارات، اور مارکیٹ کی بڑھتی ہوئی طلب کامیابی کا ایک نسخہ تیار کرنے کے لیے یکجا ہو جاتی ہے۔ صارفین کی ابھرتی ہوئی ضروریات کو پورا کرنے کے لیے اس موقع سے فائدہ اٹھائیں اور فروزن فوڈ انڈسٹری میں اپنی جگہ بنائیں۔",
    sd: " سنڌ جي منجمد خوراڪ جي صنعت جي صلاحيت کي ختم ڪريو. سنڌ ۾ منجمد خوراڪ ۽ پروسيسنگ جا منافعي جا امڪان دريافت ڪريو، جتي جديد سهولتون، معيار جي معيار، ۽ وڌندڙ مارڪيٽ جي طلب ڪاميابيءَ لاءِ هڪ طريقه ڪار ٺاهي ٿي. صارفين جي ترقي يافته ضرورتن کي پورو ڪرڻ ۽ ترقي يافته منجمد فوڊ انڊسٽري ۾ پنهنجي جڳهه کي وڌائڻ لاء هن موقعي تي سرمائيداري ڪريو."


  },

  'LoanandGrant': {
    en: "Loan & Grant Facilitation",
    ur: " قرض اور گرانٹ کی سہولت",
    sd: "قرض ۽ امداد جي سهولت"
  },

  'opportunitypara5': {
    en: "Unlock Financial Support for Your Business Ambitions in Sindh. Navigate the financial landscape with ease as we provide comprehensive assistance in securing loans and grants, connecting you to the right resources and institutions that foster entrepreneurship and fuel growth. Take advantage of our facilitation services to access the funding you need and propel your business forward in Sindh.",
    ur: "سندھ میں اپنے کاروباری عزائم کے لیے مالی امداد کو غیر مقفل کریں۔ مالیاتی منظر نامے پر آسانی کے ساتھ تشریف لائیں کیونکہ ہم قرضوں اور گرانٹس کو محفوظ کرنے میں آپ کو صحیح وسائل اور اداروں سے جوڑتے ہیں جو کاروباری اور ایندھن کی ترقی کو فروغ دیتے ہیں۔ آپ کو درکار فنڈنگ ​​تک رسائی حاصل کرنے کے لیے ہماری سہولتی خدمات سے فائدہ اٹھائیں اور سندھ میں اپنے کاروبار کو آگے بڑھائیں۔ ",
    sd: " سنڌ ۾ توهان جي ڪاروباري مقصدن لاءِ مالي مدد کوليو. مالي نظارن کي آسانيءَ سان نيويگيٽ ڪريو جيئن اسان قرضن ۽ گرانٽس کي محفوظ ڪرڻ ۾ جامع مدد فراهم ڪريون ٿا، توهان کي صحيح وسيلن ۽ ادارن سان ڳنڍي رهيا آهيون جيڪي ڪاروباري ۽ ٻارڻ جي واڌ کي فروغ ڏين ٿا. توهان کي گهربل فنڊنگ تائين رسائي حاصل ڪرڻ لاءِ اسان جي سهولتن جي خدمتن جو فائدو وٺو ۽ سنڌ ۾ پنهنجي ڪاروبار کي اڳتي وڌايو",
  },

};