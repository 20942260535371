import React from "react";
import MobileHeaderGeneric from "./MobileHeaderGeneric";
import GenericHeader from "./genericHeader";
import { useState } from "react";
import { useEffect } from "react";
import Footer from "./footer";

const PrivacyPolicy = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        // Function to update the windowWidth state when the resize event occurs
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        // Attach the event listener
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    let heading = [
        {
            title: "Information We Collect",
            description: ['Personal Information: We may collect personal information such as name, email address, phone number, etc., when voluntarily submitted by visitors through forms or other interactive features on the website.', 'Non-Personal Information: We may also collect non-personal information such as browser type, language preference, referring site, and the date and time of each visitor request.'],
        },
        {
            title: "Use of Information",
            description: ['We use the information collected to provide and improve our services, personalize the user experience, respond to inquiries and requests, and communicate with visitors.', 'Non-Personal Information: We may also collect non-personal information such as browser type, language preference, referring site, and the date and time of each visitor request.'],
        },
        {
            title: "Cookies",
            description: ['Our website may use cookies to enhance the user experience. Cookies are small files that a site or its service provider transfers to your computer\'s hard drive through your web browser (if you allow) that enables the site\'s or service provider\'s systems to recognize your browser and capture and remember certain information.'],
        },
        {
            title: "Third-party Links",
            description: ['Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites'],
        },
        {
            title: "Security",
            description: ['We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.'],
        },
        {
            title: "Consent",
            description: ['By using our website, you consent to our website\'s privacy policy.'],
        },
        {
            title: "Changes to Our Privacy Policy",
            description: ['If we decide to change our privacy policy, we will post those changes on this page. Policy changes will apply only to information collected after the date of the change.'],
        },
    ]
    return (
        <>
            {windowWidth <= 500 ? <MobileHeaderGeneric /> : <GenericHeader />}
            {windowWidth <= 500 ? (
                <div></div>
            ) : (
                <div style={{ height: "150px" }}></div>
            )}
            <div className="container_fluid">
                <div
                    className="row "
                    style={{
                        background: "",
                    }}
                >
                    <div
                        className="col-lg-12"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative",
                        }}
                    >

                        <div className="">
                            <h1
                                className={
                                    window.innerWidth <= 540
                                        ? "introductionTitle mt-4 mb-4"
                                        : "introductionTitle mt-4"
                                }
                            >
                                Privacy Policy
                            </h1>

                            {heading.map((item) => {
                                return (
                                    <>
                                        <p
                                            className={
                                                window.innerWidth <= 540
                                                    ? " mb-0"
                                                    : "feedbackpara col-8"
                                            }
                                            style={{
                                                fontSize:
                                                    window.innerWidth === 280 || window.innerWidth === 320
                                                        ? "17.5px"
                                                        : " ",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {item.title}:{" "}
                                        </p>
                                        {
                                            item['description'].map((description) => {
                                                return (

                                                    <p
                                                        className={
                                                            window.innerWidth <= 540
                                                                ? " mb-0"
                                                                : "feedbackpara col-8"
                                                        }
                                                        style={{
                                                            fontSize:
                                                                window.innerWidth === 280 || window.innerWidth === 320
                                                                    ? "17.5px"
                                                                    : " ",
                                                            paddingLeft: 40,
                                                        }}
                                                    >
                                                        {description}
                                                    </p>
                                                )
                                            })
                                        }
                                        <br/>
                                    </>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
