import { CircularProgress, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Fade } from "react-reveal";
import sindh from "../assets/logo-sindh.png";
import Group8364 from "../assets/click-logo1.png";
import { Formik } from 'formik';
import { Toaster, Toastersuccess } from './Toaster';
import { adminLogin } from '../utils/services';
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isloading, setisloading] = useState(false);


  const onHandleSubmit = (values, resetForm) => {
    ;
    setisloading(true);

    const RespObj = { ...values,};
    adminLogin( RespObj)
      .then((response) => {
        console.log(response);
        if(response?.data?.messageDescription !=="Success"){
          Toaster(response?.data?.messageDescription , "error");
        }
        // Toastersuccess(response?.data?.messageDescription);
        setisloading(false);
        resetForm();
      })
      .catch((error) => {
        Toaster(error.message, "error");
        setisloading(false);
        resetForm();
      });
  };
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    // event.preventDefault();
  };


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
    return (
        <>
            <div className="login_container">
            <div className="login_click_logo col-lg-3 l-side px-5">
              <img className="clickMainHeaderLogo " src={Group8364} alt=""></img>
            </div>
            <div
             className='login_sindh_logo'
              >
                <img
                  src={sindh}
                  alt=""
                  className="marginDPI"
                  width={windowWidth <= 1440 ? "95" : "115"}
                  height={windowWidth <= 1440 ? "115" : "135"}
                />
              </div>
                <div className="login_box">
                    <div
                        className="col-md-12 ps-md-3 px-3 justify-content-center  "
                        style={{height:"422px"}}
                    >

    <Formik
          initialValues={{
            email: "",
            password:"",
          }}
          validationSchema={Yup.object({
              email: Yup.string()
              .email("Invalid email address")
              .required("Email is required"),
              password: Yup.string().required("Name is required"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            onHandleSubmit(values, resetForm);
            setSubmitting(false);
          }}
        >
          {({
            values: {
              email,
              password,
            },
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            touched,
          }) => (
            <form onSubmit={handleSubmit}>
            <div
                className="row g-0 justify-content-between"
                style={{ width: "100%",  }}
            >
            <h1  className={
                    window.innerWidth <= 540
                        ? "LoginTitle mt-4 mb-4"
                        : "LoginTitle mt-4"
                }>Admin Login</h1>
                    <div className="col-md-12">
                        <h4> Email</h4>
                        <TextField
                            placeholder="Enter Your Email"
                            size="small"
                            style={{ width: "100%" }}
                            inputProps={{ maxLength: 50 }}
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={email}
                        />
                    {errors.email && touched.email && (
                        <span className="error">{errors.email}</span>
                      )}

                    </div>
            </div>
            <div className="row g-0" style={{ width: "100%" }}>
                    <div className="col-md-12">
                        <h4>Password</h4>
                        <TextField
                            placeholder="Enter Your Password"
                            size="small"
                            style={{ width: "100%" }}
                            type={showPassword ? "text" : "password"}
                            name="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={password}
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                        />
                          {errors.password && touched.password && (
                        <span className="error">{errors.password}</span>
                      )}
                    </div>
            </div>
            <div className="row g-0">
                <div className="col-sm-12 mt-5 d-flex justify-content-center tocentersubmitbutton ">
                    <button
                        type="submit"
                        className="submit_button w-50"
                        disabled={isloading}
                    >
                          {isloading ? (
                        <CircularProgress size={30} color="inherit" />
                      ) : (
                        "Login"
                      )}
                       
                    </button>
                </div>
            </div>
        </form>
          )}
        </Formik>               
                       
                    </div>
                </div>
            </div>
      <ToastContainer />
        </>
    )
}

export default Login