import React, { useEffect, useState } from "react";
import "../Css/header.css";
import "../Css/resource.css";
import GenericHeader from "./genericHeader";
import ss1 from "../assets/ScreenShot/s.s1.png"
import ss2 from "../assets/ScreenShot/s.s2.png"
import ss3 from "../assets/ScreenShot/s.s3.png"
import ss4 from "../assets/ScreenShot/s.s4.png"
import ss5 from "../assets/ScreenShot/s.s5.png"
import ss6 from "../assets/ScreenShot/s.s6.png"
import ss7 from "../assets/ScreenShot/s.s7.png"
import ss8 from "../assets/ScreenShot/s.s8.png"
import ss9 from "../assets/ScreenShot/s.s9.png"
import ss10 from "../assets/ScreenShot/s.s10.png"
import ss11 from "../assets/ScreenShot/s.s11.png"
import ss12 from "../assets/ScreenShot/PRMI.png"
import ss13 from "../assets/ScreenShot/worldBank.png"
import ss14 from "../assets/ScreenShot/Monthly_report.png"
import ss15 from "../assets/ScreenShot/TradePolicy.png"
import ss16 from "../assets/ScreenShot/PAKISTANINVESTMENTPOLICY2023 .png"
import ss17 from "../assets/ScreenShot/CompetitivenessofSindh.png"
import IBA_Final_Consolidation from "../assets/ScreenShot/IBA_Final_Consolidation.png"
import ToRSDIGITALMARKETING from "../assets/ScreenShot/ToRSDIGITALMARKETING.png"
import REOIDigitalMarketing from "../assets/ScreenShot/REOIDigitalMarketing.png"
import CompetitiveLivableCity from "../assets/ScreenShot/CompetitiveLivableCity.jpg"
import SPN from "../assets/ScreenShot/SPN.jpg"
import RlcoList from "../assets/ScreenShot/RlcoList.jpeg"
import RFQ from "../assets/ScreenShot/RFQ.jpeg"

import pdf1 from "../assets/DownloadDocument/Final_PRMI_Book_2_New.pdf"
import pdf2 from "../assets/DownloadDocument/Final_PRMI_Book_1.pdf"
import pdf3 from "../assets/DownloadDocument/wbgstudy.pdf"
import pdf4 from "../assets/DownloadDocument/RIE-REPORT.pdf"
import pdf5 from "../assets/DownloadDocument/pa-03-availing-of-the-net-metering-facility-impact-of-bois-reforms.pdf"
import pdf6 from "../assets/DownloadDocument/DRAP-Reforms-Evaluation-Final.pdf"
import pdf7 from "../assets/DownloadDocument/Minimum-Consensus-on-Key-Economic-Reforms.pdf"
import pdf8 from "../assets/DownloadDocument/Recommendations-on-National-Program-for-Digital-Transformation.pdf"
import pdf9 from "../assets/DownloadDocument/WEF_TheGlobalCompetitivenessReport2020.pdf"
import pdf10 from "../assets/DownloadDocument/FACT-SHEET---NHDR.pdf"
import pdf11 from "../assets/DownloadDocument/PAK.pdf"
import pdf12 from "../assets/DownloadDocument/PRMI.pptx"
import pdf13 from "../assets/DownloadDocument/WorldBank.pptm"
import pdf14 from "../assets/DownloadDocument/Monthly-report.pdf"
import pdf15 from "../assets/DownloadDocument/Tradepolicy.pdf"
import pdf16 from "../assets/DownloadDocument/FinalPakistanInvestmentPolicy.pdf"
import pdf17 from "../assets/DownloadDocument/CompetitivenessofSindh.pdf"
import pdf18 from "../assets/DownloadDocument/IBA_Final_Consolidation.pdf"
import pdf19 from "../assets/DownloadDocument/ToRSDIGITALMARKETING.pdf"
import pdf20 from "../assets/DownloadDocument/REOIDigitalMarketing.pdf"
import pdf21 from "../assets/DownloadDocument/CompetitiveLivableCityPdf.pdf"
import pdf22 from "../assets/DownloadDocument/SPNPdf.pdf"
import MappingDocuments from "../assets/MappingDocuments.zip"
import RfqDocuments from "../assets/ISSUED_RFQ.pdf"




import MobileHeaderGeneric from "./MobileHeaderGeneric";
import Footer from "./footer";
export default function Resource() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  console.log( window.location.href)

  useEffect(() => {
    // Function to update the windowWidth state when the resize event occurs
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  let arr=[
    {
      screenShot:RFQ,
      titlte:"HIRING OF TECHNOLOGY FIRM TO DESIGN, IMPLEMENT AND SUPPORT CLICK KARAOBAR MOBILE APPLICATION",
      pdf:RfqDocuments,
    
    },
    {
      screenShot:SPN,
      titlte:"SPN",
      pdf:pdf22,
    
    }
    ,
    {
      screenShot:CompetitiveLivableCity,
      titlte:"RFP",
      pdf:pdf21,
    
    },
    {
      screenShot:RlcoList,
      titlte:"Mapping Documents For RFP of S-BOSS",
      pdf:MappingDocuments,
    
    },
    {
      screenShot:ToRSDIGITALMARKETING,
      titlte:"TORSDM",
      pdf:pdf19,
    
    },
    {
      screenShot:REOIDigitalMarketing,
      titlte:"REOIDM",
      pdf:pdf20,
    
    }
    ,
    {
      screenShot:IBA_Final_Consolidation,
      titlte:"Constraints Faced By Women Owned And Managed Businesses In Karachi",
      pdf:pdf18,
    
    }
    ,
    {
      screenShot:ss1,
      titlte:"Principle based Regulatory Framework",
      pdf:pdf1
    },
    {
      screenShot:ss2,
      titlte:"Pakistan Regulatory Guidebook",
      pdf:pdf2
    },
    {
      screenShot:ss3,
      titlte:"From Swimming in Sand to High end Sustainable Growth – WBG",
      pdf:pdf3
    },
    {
      screenShot:ss4,
      titlte:"Reducing Regulatory Burden – RIE Report",
      pdf:pdf4
    },
    {
      screenShot:ss5,
      titlte:"PIDE Analytics",
      pdf:pdf5
    },
    {
      screenShot:ss6,
      titlte:" DRAP – Assessment of Reforms",
      pdf:pdf6
    },
    {
      screenShot:ss7,
      titlte:"Minimum Consensus on Key Economic Reforms",
      pdf:pdf7
    },
    {
      screenShot:ss8,
      titlte:"Recommendations on National Program for Digital Transformations",
      pdf:pdf8
    }
    ,
    {
      screenShot:ss9,
      titlte:"The Global Competitiveness Report",
      pdf:pdf9
    }
    ,
    {
      screenShot:ss10,
      titlte:"Pakistan National Human Development Report",
      pdf:pdf10
    },
    {
      screenShot:ss11,
      titlte:"Ease of Doing Business",
      pdf:pdf11
    },
    {
      screenShot:ss12,
      titlte:"Reforms Activities in Sindh (PRMI)",
      pdf:pdf12
    },
    {
      screenShot:ss13,
      titlte:"Update on BE-Ready Project World Bank",
      pdf:pdf13
    },
    {
      screenShot:ss14,
      titlte:"Trade Development Authority Of Pakistan",
      pdf:pdf14
    },
    {
      screenShot:ss15,
      titlte:"Strategic Trade Policy Framework",
      pdf:pdf15,
      link:'https://tdap.gov.pk/wp-content/uploads/2022/01/STPF-2020-25-1.pdf'
    }
    ,
    {
      screenShot:ss16,
      titlte:"Pakistan Investment Policy 2023 ",
      pdf:pdf16,
      link:'https://invest.gov.pk/sites/default/files/inline-files/Final%20PIP%202023-cs.pdf'

    }
    ,
    {
      screenShot:ss17,
      titlte:"Investment Department",
      pdf:pdf17,
      link:'https://invest.gov.pk/sites/default/files/inline-files/Final%20PIP%202023-cs.pdf'

    }
   
    
  ]
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      {windowWidth <= 500 ? <MobileHeaderGeneric /> : <GenericHeader />}
            {windowWidth <= 500 ? (
                <div></div>
            ) : (
                <div style={{ height: "150px" }}></div>
            )}

<div className='container_fluid'>
                <div
                    className="row "
                >
                    <div
                        className="col-lg-12"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            position: "relative"
                        }}
                    >
                        <div className="" >
                            <h1 className={window.innerWidth <= 540 ? "introductionTitle mt-4 mb-4" : "introductionTitle mt-4"}>Downloads</h1>
                        </div>
                    </div>
                </div>

            </div>
       <div className="card_wrapper"
      >
       {
          arr.map((item)=>{
            return  <div  className="cardMain1 ">
            <div className="cardResource">
              <div className="cardInnerLeft">
                <img 
                style={{
                  boxShadow: '5px 8px 5px rgba(0, 0, 0, 0.2)'}} 
                  src={item?.screenShot}  
                   width="100%"
                   height="250px" />
              </div>
              <div className="cardInnerRight" style={{boxShadow: '5px 8px 5px rgba(0, 0, 0, 0.2)'}}>
                <h2 className="cardHeading">
               {item?.titlte}
                </h2>
                <button type="button" className="resourceButton">
                  <a href={item?.pdf} style={{color:"white",textDecoration:"none"}} target="_blank">View Documents</a>
                </button>
              </div>
            </div>
            
          </div>
          })
         }
       </div>
       <Footer/>
    </>
  );
}
